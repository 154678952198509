import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as XLSX from 'xlsx';
import fileSaver from 'file-saver';
import Can  from '../../auth/ability';
import store, { history } from '../../store';
import { useTracker } from '@colliers-international/usage-tracker';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import formatStat from '../../common/stats/formatStat';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import NotificationMessage from '../../common/NotificationMessage';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import DatatypeIcons from '../../account/common/DatatypeIcons';
import { 
  exportSavedSearch
} from '../../research/searches/actions';
import api from '../../services/api';
import datatypes from '../../common/datatypes';
import { formatContent } from '../../research/table/Cell';
import styles from './module.css';

const SavedSearchRow = ({ classes, ...props }) => {
  const { search: { title, timestamp, value, description, frequency },
    gridViews: { columns },
    fetchGridViews,
    onDeleteSavedSearch,
    onUpdateSavedSearch,
    savedSearchDeleted,
    savedSearchUpdated,
    searchResults,
    selectSavedSearch,
  } = props;

  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedGridViews, setSelectedGridViews] = useState({});
  const [grids, setGrids] = useState({});
  const [exportRecords, setExportRecords] = useState({});
  const [exportRequested, setExportRequested] = useState(false);
  const [exportSearchId, setExportSearchId] = useState(null);  

  const toggleDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };
  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setNotificationMessage('');
    setNotificationOpen(false);
    setIsDeleting(false);
  };
  const handleDelete = () => {
    setIsDeleting(true);
    return api.destroy('/savedSearches', value.id)
      .then((result) => {
        setIsDeleting(false);
        setDeleteDialogOpen(false);
        savedSearchDeleted(value);
        onDeleteSavedSearch(value);
        return result;
      })
      .catch((error) => {
        setIsDeleting(false);
        setDeleteDialogOpen(false);
        setNotificationOpen(false);
        setNotificationMessage(
          <NotificationMessage text={`Error deleting search [${error.message}]`} type={'error'} />
        );
        setNotificationOpen(true);
      });
  };
  const handleNotificationClose = () => {
    setNotificationOpen(false);
    setNotificationOpen(false);
    setNotificationMessage('');
  };

  const [savedSearchTitle, setSavedSearchTitle] = useState(title);
  const [savedSearchDescription, setSavedSearchDescription] = useState(description);
  const [savedSearchFrequency, setSavedSearchFrequency] = useState(frequency);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const toggleEditDialog = () => {
    setEditDialogOpen(true);
  };
  const toggleExportDialog = () => {
    setExportDialogOpen(true);
  };
  const handleExportCancel = () => {
    setNotificationMessage('');
    setNotificationOpen(false);
    setExportDialogOpen(false);
  };
  const handleEditCancel = () => {
    setIsEditing(false);
    setNotificationMessage('');
    setNotificationOpen(false);
    setEditDialogOpen(false);
    setSavedSearchTitle(title);
    setSavedSearchDescription(description);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const datatypes = value?.enabledDataTypes; 
        const promises = datatypes.map((datatype) => fetchGridViews(datatype));
        const results = await Promise.all(promises);
        results.forEach((r) => {
          const items = r.items;
          if (items.length > 0) {
            const groupedItems = {};
            for (const item of items) {
              const type = item.type;
              if (!groupedItems[type]) {
                groupedItems[type] = [];
              }
              groupedItems[type].push(item);
            }
            setGrids((prevState) => {
              const newState = { ...prevState };

              for (const [type, newItems] of Object.entries(groupedItems)) {
                if (!newState[type]) {
                  newState[type] = [];
                }
                newState[type] = [...newState[type], ...newItems];
              }
              return newState;
            });
          }
        });
      } catch (error) {
        console.error('error fetching grids');
      }
    };
    fetchData();
  }, [value]);

  const loadSearch = () => {
    selectSavedSearch(value);
    window.location.hash = value.urlHash;
    history.replace({
      ...location,
      pathname: '/research/map',
      hash: value.urlHash
    });
  };

  const handleGridChange = (e, datatype) => {
    const value = e.target.value;
    setSelectedGridViews((prevState) => ({ ...prevState, [datatype]: value }));
  };

  const { trackEvent } = useTracker();

  const exportSearch = () => { 
    store.dispatch(exportSavedSearch(value));
  };

  const csvCellContent = (source, { aggregation, key }) => {
    if (aggregation && key in source) {
      return Array.isArray(source[key]) ? source[key].join(', ') : source[key];
    }
  
    if (key in source) {
      const value = source[key];
      return Array.isArray(value) ? value.join(', ') : value;
    }
  
    const nestedKey = `properties.${key}`;
    if (nestedKey in source) {
      const value = source[nestedKey];
      return Array.isArray(value) ? value.join(', ') : value;
    }
  
    return '';
  };
  
  const flattenObject = (obj, prefix = '') => {
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];
      const prefixedKey = prefix ? `${prefix}.${key}` : key;
      if (key === 'properties' && Array.isArray(value) && value.length > 0) {
        const firstProperty = value[0];
        Object.entries(firstProperty).forEach(([propKey, propValue]) => {
          acc[`properties.${propKey}`] = propValue;
        });
      } else if (Array.isArray(value)) {
        if (value.length > 0 && typeof value[0] === 'object') {
          acc[prefixedKey] = value.map((item) => JSON.stringify(item)).join('; ');
        } else {
          acc[prefixedKey] = value.join('; ');
        }
      } else if (typeof value === 'object' && value !== null) {
        Object.assign(acc, flattenObject(value, prefixedKey));
      } else {
        acc[prefixedKey] = value;
      }
      return acc;
    }, {});
  };
  
  const getExportRecords = (searchResults, value, grids) => {
    const searchResultsDatatypes = Object.fromEntries(
      Object.entries(searchResults).filter(([, value]) => value.results && value.results.length > 0)
    );
  
    return Object.keys(searchResultsDatatypes || {}).reduce((acc, type) => {
      grids[type] = grids[type] || [];
      grids[type].unshift({ 
        id: 'Default',
        columns: datatypes[type].columns.filter((col) => col.key !== 'anticipatedTenants'),
        title: 'Default' 
      });
  
      if (value.enabledDataTypes.includes(type)) {
        const results = searchResultsDatatypes[type]?.results || [];
        if (results.length > 0) {
          acc[type] = results.map(({ _source }) => {
            const selectedGridView = selectedGridViews[type] ? selectedGridViews[type] : 'Default';
            const selectedGrid = grids?.[type]?.find((grid) => grid.title === selectedGridView);
            const isCustomView = selectedGridView !== 'Default';

            let gridColumns = (selectedGrid?.columns || []).map((col) => {
              const defaultColumn = columns?.[type]?.find((defaultCol) => defaultCol.key === col.key) || {};
              return {
                ...defaultColumn,
                ...col,
              };
            });

            if (isCustomView) {
              gridColumns = gridColumns.filter((col) => col.isEnabled !== false);
            }
            
            const flatSource = flattenObject(_source);
            const formattedSource = gridColumns.reduce((formattedRow, col) => {
              let title = col.title || col.key || 'Unknown Column';

              // if title doesn't exist
              if (!col.title) {
                title = col.key.replace(/\./g, ' ')
                  .replace(/\b\w/g, (char) => (char.toUpperCase()));
              }

              if (!col.title && datatypes[type]) {
                const matchingSchema = datatypes[type].columns.find((schema) => schema.key === col.key);
                if (matchingSchema && matchingSchema.title) {
                  title = matchingSchema.title;
                }
              }

              let rawContent = csvCellContent(flatSource, { aggregation: col.aggregation, key: col.key });
  
              if (Array.isArray(rawContent)) {
                rawContent = rawContent.join(', ');
              }
  
              formattedRow[title] = formatContent({
                column: col,
                content: rawContent,
                item: flatSource,
                dateFormat: 'YYYY-MM-DD',
              });
              
              return formattedRow;
            }, {});
            return formattedSource; 
          });
        }
      }
      return acc;
    }, {});
  };
  
  const generateReport = (currentSearchResults) => {
    const records = getExportRecords(currentSearchResults, value, grids);
    setExportRecords(records);
  
    const workbook = XLSX.utils.book_new();
  
    value.enabledDataTypes.forEach((type) => {
      if (!records[type] || records[type].length === 0) {
        const defaultColumns = datatypes[type]?.columns.map((col) => col.title || col.key) || [];
        records[type] = [Object.fromEntries(defaultColumns.map((col) => [col, '']))];
      }
      const worksheet = XLSX.utils.json_to_sheet(records[type] || [{}]);

      XLSX.utils.book_append_sheet(workbook, worksheet, type.charAt(0).toUpperCase() + type.slice(1));
    });
  
    const fileName = `${value.title || 'Saved Search Export'}.xlsx`;
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const file = new Blob([excelBuffer], { type: 'application/octet-stream' });
    fileSaver.saveAs(file, fileName);
  };

  const triggerExportWithDelay = () => {
    generateReport(searchResults);
    setExportRequested(false);
    setExportSearchId(null);
    setExportDialogOpen(false);
  };
  
  const handleExport = () => {
    try {
      setExportRequested(true);
      setExportSearchId(value.id);
      exportSearch();
      generateReport();
      const recordCount = Object.values(exportRecords).reduce((total, arr) => total + arr.length, 0);
      trackEvent({ 
        category: 'Discover Saved Search', 
        action: 'Clicked', 
        name:'Export Saved Search', 
        value: `${recordCount} records`
      });
    } catch (err) {
      console.error('Export failed:', err);
    }
  };

  useEffect(() => {
    let timeout;
    if (exportRequested && exportSearchId === value.id) {
      const allSearchesComplete = value.enabledDataTypes.every(
        (type) => searchResults[type] && searchResults[type].isSearching === false
      );
  
      if (allSearchesComplete) {
        timeout = setTimeout(triggerExportWithDelay, 2000); // give time for component state to catch up with redux
      }
    }
  
    return () => clearTimeout(timeout);
  }, [searchResults, exportRequested, exportSearchId, value.id]);
  
  const handleEdit = () => {
    let resource = '/savedSearches';
    resource += `/${value.id}`;
    const saveMode = 'update';
    const data = {
      enabledDataTypes: value.enabledDataTypes,
      urlHash: value.urlHash,
      filters: value.filters,
      query: value.query,
      selectedItems: value.selectedItems,
      title: savedSearchTitle,
      description: savedSearchDescription,
      frequency: savedSearchFrequency
    };
    Object.assign(data, { title: savedSearchTitle, description: savedSearchDescription });
    setIsEditing(true);
    return api.update[saveMode](resource, data)
      .then((result) => {
        onUpdateSavedSearch(savedSearchTitle, savedSearchDescription, savedSearchFrequency, value);
        savedSearchUpdated(value);
        setIsEditing(false);
        setEditDialogOpen(false);
        setNotificationMessage(<NotificationMessage
          secondaryText={`Search ${saveMode}d`}
          text={savedSearchTitle}
          type={'success'} />);
        setNotificationOpen(true);
        setSavedSearchTitle(savedSearchTitle);
        setSavedSearchDescription(savedSearchDescription);
        setSavedSearchFrequency(savedSearchFrequency);
        return result;
      })
      .catch((error) => {
        setIsEditing(false);
        setNotificationOpen(false);
        setNotificationMessage(<NotificationMessage text={`Error saving search [${error.message}]`} type={'error'} />);
        setNotificationOpen(true);
        console.error('handleSave error: ', error);
      });
  };
  const handleTitleChange = (event) => {
    setSavedSearchTitle(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setSavedSearchDescription(event.target.value);
  };

  const handleFrequencyChange = (event, value) => {
    event.preventDefault();
    setSavedSearchFrequency(value);
  };

  const exportModules = (savedSearch) => {
    const datatypes = savedSearch.enabledDataTypes;
    const filteredData = datatypes?.filter((data) => data !== ('news' || 'tims'));
    return filteredData && filteredData?.map((datatype) => (
      <>
        <TableRow>
          <TableCell style={{ border: 'none', margin: 0, padding: 0 }}>
            <DatatypeIcons types={[datatype]} layout={'column'} />
          </TableCell>
          <TableCell style={{ border: 'none' }}>
            <Typography>{datatype.charAt(0).toUpperCase() + datatype.slice(1)}</Typography>
          </TableCell>
          <TableCell style={{ border: 'none', paddingLeft: 0 }}>
            <FormControl fullWidth className={classes.exportForm}>
              <Select
                labelId="grid-view"
                id="grid-view"
                value={selectedGridViews[datatype] || 'Default'}
                onChange={(e) => handleGridChange(e, datatype)}
                className={classes.exportSelect}>
                <MenuItem key="Default" value="Default" className={classes.exportMenuItem}>Default</MenuItem>
                { grids?.[datatype]?.map((grid, index) => 
                  grid.type === datatype && (
                    <MenuItem key={index} value={grid.title} className={classes.exportMenuItem}>{grid.title}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </TableCell>
        </TableRow>
      </>
    ));
  };
  
  return (
    <React.Fragment>
      <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
        <TableCell className={classes.savedSearchTableCell}>
          <p
            className={classes.savedSearchColumnName}
            onClick={loadSearch}>{title}</p>
        </TableCell>
        <TableCell
          className={classes.savedSearchTableCell}>{description}</TableCell>
        <TableCell className={classes.savedSearchTableCell}>
          <div className={classes.savedSearchColumnModules}>
            <DatatypeIcons types={value.enabledDataTypes} />
          </div>
        </TableCell>
        <TableCell className={classes.savedSearchTableCell}>{moment(timestamp).format('M/D/YYYY h:mm A')}</TableCell>
        <TableCell className={classes.savedSearchTableCell}>
          {formatStat(frequency, { format: 'properCaseString' })}
        </TableCell>
        <TableCell className={classes.savedSearchTableCell}>
          <div className={classes.savedSearchColumnActions}>
            <Tooltip title="Edit Name or Description" placement="right-start">
              <IconButton
                onClick={toggleEditDialog}
                size="small">
                <Edit />
              </IconButton>
            </Tooltip>
            <Can do="create" on="allowExportUnlimited">
              <Tooltip title="Export Data" placement="right-start">
                <IconButton
                  onClick={toggleExportDialog}
                  size="small">
                  <CloudDownloadOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Can>
            <Tooltip title="Delete Search" placement="top">
              <IconButton
                onClick={toggleDeleteDialog}
                size="small">
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        </TableCell>
      </TableRow>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}>
        <DialogTitle>Delete Saved Search</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you wish to delete this alert?
            <span style={{ display: 'block', fontWeight: 'bold' }}>{title}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={isDeleting}
            onClick={handleDeleteCancel}>No</Button>
          <Button
            color="primary"
            disabled={isDeleting}
            onClick={handleDelete}
            variant="contained">Yes</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        className={classes.exportDialog}
        open={exportDialogOpen}
        onClose={handleExportCancel}>
        <DialogTitle className={classes.gridViewHeader}>EXPORT DATA</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p
              className={classes.savedSearchExportColumnName}>{title}</p>
            <Typography 
              onClick={loadSearch}
              component="span"
              className={classes.savedSearchExportViewButton}>View</Typography>
          </DialogContentText>
          <p className={classes.gridViewLayout}>Grid View Layout</p>
          {exportModules(value)}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={isDeleting}
            onClick={handleExportCancel}
            variant="outlined">Cancel</Button>
          <Button
            color="primary"
            disabled={isDeleting}
            onClick={handleExport}
            variant="contained">Download
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={editDialogOpen}
        onClose={handleEditCancel}>
        <DialogTitle>Manage Search</DialogTitle>
        <div className={classes.savedSearchModules}>
          <DatatypeIcons types={value.enabledDataTypes} />
        </div>
        <Close className={classes.close} onClick={handleEditCancel} />
        <DialogContent>
          <div>
            <TextField
              fullWidth
              label={'Title'}
              defaultValue={title} 
              onChange={handleTitleChange} />
          </div>
          <div>
            <TextField
              fullWidth
              label={'Description'}
              defaultValue={description}
              onChange={handleDescriptionChange} />
          </div>
          <div>
            <Typography className={classes.notifyText}>
              Notify me of new changes
            </Typography>
            <Typography className={classes.notifySubText}>
              Receive email alerts when new items match this saved search.
            </Typography>
          </div>
          <div>
            <ToggleButtonGroup
              value={savedSearchFrequency}
              exclusive
              onChange={handleFrequencyChange}
              name="frequency"
              aria-label="frequency"
              className={classes.toggleButtons}>
              <ToggleButton value="never">NEVER</ToggleButton>
              <ToggleButton value="daily">DAILY</ToggleButton>
              <ToggleButton value="weekly">WEEKLY</ToggleButton>
            </ToggleButtonGroup>
          </div>
        </DialogContent>
        <DialogActions>
          <Tooltip title="Delete Search" placement="top">
            <Button
              onClick={toggleDeleteDialog}
              size="small"
              className={classes.deleteSearch}>
              Delete Search
            </Button>
          </Tooltip>
          <Button
            color="primary"
            disabled={isEditing}
            className={classes.cancelButton}
            variant="outlined"
            onClick={handleEditCancel}>Cancel</Button>
          <Button
            color="primary"
            disabled={isEditing}
            onClick={handleEdit}
            className={classes.updateButton}
            variant="contained">Update</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={notificationOpen}
        autoHideDuration={5000}
        onClose={handleNotificationClose}>
        <SnackbarContent
          style={{ minWidth: 'fit-content' }}
          message={notificationMessage} />
      </Snackbar>
    </React.Fragment>
  );
};

SavedSearchRow.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array,
  fetchGridViews: PropTypes.func.isRequired,
  gridViews: PropTypes.object.isRequired,
  onDeleteSavedSearch: PropTypes.func.isRequired,
  onUpdateSavedSearch:PropTypes.func.isRequired,
  results: PropTypes.array,
  savedSearchDeleted: PropTypes.func,
  savedSearchUpdated: PropTypes.func,
  search: PropTypes.object.isRequired,
  searchResults: PropTypes.object.isRequired,
  selectGridView: PropTypes.func.isRequired,
  selectSavedSearch: PropTypes.func
};

export default withStyles(styles)(SavedSearchRow);
