import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import Checkbox from '@material-ui/core/Checkbox';
import AttachFile from '@material-ui/icons/AttachFile';
import _ from 'lodash';
import moment from 'moment';
import striptags from 'striptags';
import { getParcelPath } from '../../common/getParcelPath';
import { notClickablePropertyDetail } from './Table';

export const formatContent = ({ column, item, dateFormat, ...props }) => {
  let normalizedContent = props.content;
  
  // Force number conversion for specific properties due to duplicate col
  const numericKeys = [
    'properties.yearBuilt',
    'properties.buildingHeightFloors',
    'properties.zipCode',
    'properties.landArea'
  ];

  if (numericKeys.includes(column.key)) {
    const value = Number(normalizedContent);
    return isNaN(value) ? null : value;
  }

  if (column.key.includes('properties[0].')) {
    const normalizedColumnKey = column.key.replace('properties[0].', 'properties.');
    if (_.get(props, `processedKeys.${normalizedColumnKey}`)) {
      return;
    }

    let resolvedValue = _.get(item, normalizedColumnKey);

    if (_.isUndefined(resolvedValue)) {
      const baseKey = normalizedColumnKey.split('.').slice(0, -1).join('.');
      const leafKey = normalizedColumnKey.split('.').pop();
      const flattenedObject = item[baseKey];

      if (flattenedObject && _.isObject(flattenedObject)) {
        resolvedValue = flattenedObject[leafKey];
      }
    }

    props.content = resolvedValue;
    normalizedContent = resolvedValue;
    column = { ...column, key: normalizedColumnKey };

    _.set(props, `processedKeys.${normalizedColumnKey}`, true);
  }

  if (_.isNil(normalizedContent)) {
    return;
  }

  let rawContent = normalizedContent;
  let content;
  let overrideFormat;
  if (column.aggregation === 'concat' || column.type === 'array') {
    if (!Array.isArray(rawContent)) {
      rawContent = rawContent ? [rawContent] : [];
    }
  }
  if (Array.isArray(rawContent)) {
    if (column.key === 'customId') {
      content = rawContent;
    } else {
      rawContent = rawContent.filter((val) => val !== null && val !== undefined).map((val) => {
        if (typeof val === 'object') {
          return JSON.stringify(val);
        }
        return String(val);
      });
      content = rawContent.join(', ');
    }
  } else {
    content = rawContent;
  }

  // string-like aggregation
  if (column.aggregation === 'concat') {
    content = [];
    if (column.type === 'array' && column.format === 'array') {
      rawContent.forEach((arr) => {
        if (!Array.isArray(arr)) arr = arr ? [arr] : [];
        arr.forEach((val) => {
          if (val && (column.allowDuplicates || !content.includes(val))) {
            content.push(val);
          }
        });
      });
    } else {
      const otherColumnProps = { ...column };
      delete otherColumnProps.aggregation;
      delete otherColumnProps.allowDuplicates;
      rawContent.forEach((val) => {
        const formattedVal = formatContent({
          column: otherColumnProps,
          content: val,
          item,
          dateFormat,
        });
        if (formattedVal && (column.allowDuplicates || !content.includes(formattedVal))) {
          content.push(formattedVal);
        }
      });
      overrideFormat = 'aggArray';
    }

    // additive aggregation
  } else if (column.aggregation === 'add') {
    content = rawContent.reduce((acc, cur) => acc + (cur || 0), 0) || '';

    // nested objects in an array (custom ids)
  } else if (column.key === 'customId') {
    if (Array.isArray(rawContent)) {
      content = rawContent.map((item) => {
        if (typeof item === 'string') {
          try {
            item = JSON.parse(item);
          } catch (e) {
            console.error('Failed to parse customId entry:', item, e);
            return '';
          }
        }
        return `${item.name}: ${item.id}`;
      }).join('; ');
    } else if (rawContent && typeof rawContent === 'string') {
      try {
        const parsed = JSON.parse(rawContent);
        content = `${parsed.name}: ${parsed.id}`;
      } catch (e) {
        console.error('Failed to parse single customId:', rawContent, e);
        content = '';
      }
    } else if (rawContent && typeof rawContent === 'object') {
      content = `${rawContent.name}: ${rawContent.id}`;
    } else {
      content = '';
    }
    // (no aggregation)
  } else {
    content = rawContent;
  }

  switch (overrideFormat || column.format) {
    case 'signatory':
      return content.name;

    case 'signatories':
      return Array.from(content)
        .flatMap((value) => {
          const entries = value.split(';').map((entry) => entry.trim());

          return entries.map((entry) => {
            try {
              const parsed = JSON.parse(entry);
              return parsed.name || 'Unknown Name';
            } catch (err) {
              console.error(`Error parsing entry: ${entry}`, err);
              return '';
            }
          });
        })
        .join('; ');

    case 'aggArray':
      if (content.length === 0) return '';
      return Array.from(content).join('; ');

      // case 'tourDates':
      //   if (value.length === 0) return '';
      //   return value.map((v) => formatStat(v.date, { format: 'date' })).join(' / ');

    case 'object':
      if (column.key === 'property') {
        if (content.canonicalName) return content.canonicalName;
        return _(content).at(['name', 'address'])
          .compact()
          .join(' - ');
      }
      return content.toString();

    case 'currency':
      if (content) {
        return Number(content);
      }
      return null;

    case 'number':
      if (!content) {
        return '';
      }
      return Number(content);

    case 'parkingRatio':
      if (content) {
        return `${content}/1000`;
      }
      return '';

    case 'ratio':
      if (content) {
        return Number(content) / 100;
      }
      return null;

    case 'date':
    case 'date-time':
      if (content) {
        return moment(content).format(dateFormat ? dateFormat : 'MMM Do, YYYY');
      }
      return '';

    case 'company':
      if (column.key === 'developer') {
        const devName = content?.name || _.get(item, 'developer.name') || item['developer.name'];
        return devName || '';
      }
      return content.name;

    case 'boolean':
      if (content === false) {
        return 'No';
      }
      if (content === true) {
        return 'Yes';
      }
      return '';
    case 'parkingDetails':
    case 'spaceUses': {
      if (!content) return '';

      const entries = Array.isArray(content) ? content : [content];

      const parsedEntries = entries.flatMap((entry) => {
        if (typeof entry === 'string') {
          return entry.split(';').map((part) => {
            const trimmed = part.trim();
            if (trimmed.startsWith('{') && trimmed.endsWith('}')) {
              try {
                return JSON.parse(trimmed);
              } catch (e) {
                console.error(`Failed to parse ${column.format} entry as JSON:`, trimmed, e);
                return null;
              }
            }
            return null;
          }).filter(Boolean);
        }
        if (typeof entry === 'object') {
          return [entry];
        }
        return [];
      });

      return parsedEntries
        .map((entry) => entry.type || '')
        .filter(Boolean)
        .join(', ');
    }

    case 'officeSqft':
      return ((content || []).find(({ type }) => /office/i.test(type)) || {}).area;

    case 'rentSchedule':

      if (!content || content.length === 0) {
        return '';
      }

      const entries = Array.isArray(content) ? content : [content];

      return entries.flatMap((entry) => {
        if (typeof entry === 'string') {
          return entry.split(';').map((part) => {
            try {
              const parsed = JSON.parse(part.trim());
              return `Start: ${parsed.start || 'N/A'}, End: ${parsed.end || 'N/A'}, Rate: ${parsed.rate || 'N/A'}`;
            } catch (e) {
              console.error('Error parsing rentSchedule entry:', part, e);
              return '';
            }
          });
        }

        if (typeof entry === 'object') {
          return `Start: ${entry.start || 'N/A'}, End: ${entry.end || 'N/A'}, Rate: ${entry.rate || 'N/A'}`;
        }

        return '';
      }).join('; ');

    case 'phoneNumbers':
      if (content.length === 0) return '';
      return content.map((phone) => phone.number).join(', ');

    case 'unitMixes':
      if (content.length === 0) {
        return '';
      }
      return content
        .map((unit) => {
          try {
            const parsedUnit = JSON.parse(unit);
            return `${parsedUnit.area || 'N/A'}(sf) / ${parsedUnit.price || 'N/A'}`;
          } catch (error) {
            console.error('Error parsing unitMixes:', unit, error);
            return '';
          }
        })
        .join('; ');

    case 'year':
      if (!content || isNaN(Number(content))) {
        return null;
      }
      return Number(content);
      
    default:
      const numericKeys = [
        'property.rentableBuildingArea',
        'properties.rentableBuildingArea',
        'askingRate.min',
        'askingRate.max',
        'areaChange.value',
        'floors',
      ];

      if (numericKeys.includes(column.key)) {
        if (!content || (Array.isArray(content) && content.length === 0)) {
          return null;
        }

        const value = Array.isArray(content) ? content[0] : content;
        return isNaN(Number(value)) ? null : Number(value);
      }

      return striptags(content?.toString() || '');
  }
};

const Cell = ({
  classes,
  column,
  content = null,
  currentListView,
  toggleSelectedItem,
  row,
  isTitleCell }) => {
  const showDetails = async () => {
    const id = row.id || '';
    const property = (currentListView === 'property') ? row : row.property || row.properties[0];
    const hash = property.hash || '';
    const rescourId = property.rescourId || row.id;
    const slug = `${hash}|${rescourId}`;

    const pathname = await getParcelPath({
      address: property.address || row.address,
      id,
      slug,
      type: currentListView,
      view: 'table',
    });

    browserHistory.push({
      ...location,
      pathname
    });
  };

  if (column.format === 'checkbox') {
    return (
      <Checkbox
        checked={content}
        color="default"
        onChange={toggleSelectedItem}
        style={{ marginLeft: -6 }} />
    );
  }

  if (column.format === 'documents') {
    return (
      <span className={classes.documentsTableCellWrapper}>
        <AttachFile fontSize="inherit" />
        <span className={classes.documentsTableCellContent}>{content ? content.length : '0'}</span>
      </span>
    );
  }

  if (isTitleCell && !(notClickablePropertyDetail(currentListView))) {
    if (_.isNil(content)) { return <span style={{ width: '100%', height: '100%' }} onClick={showDetails} />; }
    return (
      <span onClick={showDetails} style={{ width: '100%' }}>
        {formatContent({ column, content, item: row, dateFormat: 'MMM Do, YYYY' })}
      </span>
    );
  }

  // Virtual-Type in CURA-API using spaceUses (content reassigned to hit formatContent case)
  if (column.key === 'officeSqft') {
    content = row.spaceUses;
  }

  if (_.isNil(content)) { return <span />; }
  return <span>{formatContent({ column, content, item: row, dateFormat: 'MMM Do, YYYY' })}</span>;
};

Cell.propTypes = {
  classes: PropTypes.object,
  column: PropTypes.object.isRequired,
  columnIndex: PropTypes.number,
  content: PropTypes.any,
  currentListView: PropTypes.string.isRequired,
  isTitleCell: PropTypes.bool,
  row: PropTypes.object,
  rowIndex: PropTypes.number,
  toggleSelectedItem: PropTypes.func.isRequired,
};

export default Cell;
