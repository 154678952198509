/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import GestureIcon from '@material-ui/icons/Gesture';
import { ControlContainer } from '@colliers-international/react-leaflet-components';
import { ButtonGroup, Button, Tooltip, Popper, Collapse, ClickAwayListener } from '@material-ui/core';
import { COLLIERS_RED } from '../../../styles/theme';

const ExitButton = withStyles((theme) => ({
  root: {
    'backgroundColor': COLLIERS_RED,
    'borderRadius': '0px 0px 5px 5px',
    'boxShadow': 'inset 0px 2px 3px 0px #0000005e',
    'color': theme.palette.common.white,
    'height': '21px',
    'fontSize': 11,
    'fontWeight': 500,
    '&:hover': {
      backgroundColor: '#ee8492'
    }
  },
}))(Button);

const RadiusButtonIcon = () => (
  <svg
    id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px"
    y="0px"
    viewBox="0 0 612 792">
    <path
      className="st0" d="M306,244.9c-83.7,0-151.6,67.8-151.6,151.6S222.3,548.1,306,548.1s151.6-67.8,151.6-151.6
	S389.7,244.9,306,244.9z M306,517.9c-66.8,0-121.4-54.6-121.4-121.4S239.2,275.1,306,275.1s121.4,54.6,121.4,121.4
	S372.8,517.9,306,517.9z M306,305.9c-49.8,0-90.6,40.8-90.6,90.6s40.8,90.6,90.6,90.6s90.6-40.8,90.6-90.6S355.8,305.9,306,305.9z
	 M306,457.4c-32.9,0-60.9-27-60.9-60.9s28.1-60.9,60.9-60.9s60.9,28.1,60.9,60.9S338.9,457.4,306,457.4z M306,366.3
	c-17,0-30.2,13.8-30.2,30.2c0,16.4,13.8,30.2,30.2,30.2c16.4,0,30.2-13.8,30.2-30.2C336.2,380.1,323,366.3,306,366.3z" />
  </svg>
);

export const DrawControlContainer = ({ activeDraw, handleDelete, exitDelete, exitDeleteMode, map, layers, setDrawType, radius, setRadius, classes }) => {
  const [value, setValue] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [cancelDraw, setCancelDraw] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [arrowRef, setArrowRef] = React.useState(null);
  const [placement, setPlacement] = React.useState();
  const [cancelDelete, setCancelDelete] = React.useState(exitDelete);

  useEffect(() => {
    if (!activeDraw) {
      setValue(null);
      setCancelDraw(activeDraw);
    }
  }, [activeDraw]);

  useEffect(() => {
    if (layers.length === 0) {
      setValue(null);
      setCancelDelete(false);
      exitDeleteMode();
    }
  }, [layers]);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const closePopper = () => {
    setOpen(false);
    setValue(null);
  };

  const handleDeleteAction = (e) => {
    setCancelDraw(false);
    setValue(e.currentTarget.name);
    if (layers.length === 0) return;
    if (cancelDelete) {
      setCancelDelete(false);
      exitDeleteMode();
    } else {
      handleDelete();
      setCancelDelete(true);
    }
  };

  const handleDrawButtonSelect = (e) => {
    setCancelDelete(false);
    setDrawType(e);
    setCancelDraw(true);
    setOpen(false);
    setValue(e.currentTarget.name);
    if (e.currentTarget.name === 'cancelEl') {
      setCancelDraw(false);
    }
  };

  const handleDeleteButtonSelect = (e) => {
    setDrawType(e);
    setCancelDelete(true);
    setOpen(false);
    setValue(e.currentTarget.name);
    if (e.currentTarget.name === 'clearEl') {
      setCancelDelete(false);
      exitDeleteMode();
    }
  };

  const handleRadiusButtonSelect = (newPlacement) => (e) => {
    setCancelDelete(false);
    setValue(e.currentTarget.name);
    setAnchorEl(e.currentTarget);
    setOpen(true);
    setPlacement(newPlacement);
    handleRadiusSelect(e, radius);
  };

  const handleRadiusSelect = (e, radius) => {
    setDrawType(e);
    setCancelDraw(true);
    setRadius(radius);
  };

  const selectedButton = (name) => {
    if (value === name) {
      return {
        backgroundColor: '#bcbcbc',
        color: 'white !important'
      };
    }
    return {};
  };

  map.on('click', () => {
    setCancelDelete(false);
  });

  return (
    <ControlContainer style={{ height: '40px' }}>
      <ButtonGroup
        size="small"
        variant="contained"
        aria-label="small button group">
        <Tooltip arrow title="Draw">
          <Button
            className={classes.lightButton}
            name="drawFreeEl"
            value="click and hold to draw shape"
            onClick={handleDrawButtonSelect}
            style={selectedButton('drawFreeEl')}><GestureIcon /></Button>
        </Tooltip>
        <Tooltip arrow title="Radius">
          <Button
            className={classes.lightButton}
            name="drawCircleEl"
            onClick={handleRadiusButtonSelect('bottom')}
            style={selectedButton('drawCircleEl')}><RadiusButtonIcon />
          </Button>
        </Tooltip>
        <Tooltip arrow title="Enter Delete Mode">
          <Button
            className={classes.lightButton}
            name="deleteEl"
            onClick={handleDeleteAction}
            style={selectedButton('deleteEl')}><DeleteIcon /></Button>
        </Tooltip>
      </ButtonGroup >
      <Collapse in={cancelDraw} className={classes.collapse}>
        <ExitDrawMode handleButtonSelect={handleDrawButtonSelect} />
      </Collapse>
      <Collapse in={cancelDelete} className={classes.collapse}>
        <ExitDeleteMode handleButtonSelect={handleDeleteButtonSelect} />
      </Collapse>
      {open && <ClickAwayListener
        onClickAway={closePopper}>
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          className={classes.toolTipContainer}
          modifiers={{
            arrow: {
              enabled: true,
              element: arrowRef
            }
          }}>
          {open && <span ref={setArrowRef} className={classes.arrow} />}
          <DrawControlTooltip
            open={open}
            radius={radius}
            closeToolTip={handleTooltipClose}
            setRadius={handleRadiusSelect} />
        </Popper>
      </ClickAwayListener>}

    </ControlContainer >
  );
};

// eslint-disable-next-line react/prop-types
const ExitDrawMode = ({ handleButtonSelect }) => {
  return (<Tooltip arrow title="Exit Draw Tool">
    <ExitButton name="cancelEl" onClick={handleButtonSelect}>Exit Draw Mode</ExitButton>
  </Tooltip>);
};

const ExitDeleteMode = ({ handleButtonSelect }) => {
  return (<Tooltip arrow title="Exit Delete Mode">
    <ExitButton name="clearEl" onClick={handleButtonSelect}>Exit Delete Mode</ExitButton>
  </Tooltip>);
};

const DrawControlTooltip = ({ closeToolTip, open, radius, setRadius }) => {
  const formatRadius = (selectedRadius) => {
    if (selectedRadius === 0.25) {
      return '¼';
    }
    if (selectedRadius === 0.5) {
      return '½';
    }
    return selectedRadius;
  };

  const handleRadiusSelect = (selectedRadius) => () => {
    closeToolTip();
    setRadius({ currentTarget: { name: 'drawCircleEl' } }, selectedRadius);
  };
  if (open) {
    return (<Paper elevation={2}>
      <List>
        {[0.25, 0.5, 1, 2, 3, 5, 10,20,30].map((selectedRadius) => {
          return (<ListItem
            key={selectedRadius}
            button
            onClick={handleRadiusSelect(selectedRadius)}>
            {radius === selectedRadius && (
              <ListItemIcon><CheckIcon /></ListItemIcon>
            )}
            <ListItemText> {formatRadius(selectedRadius)} mi</ListItemText>
          </ListItem>);
        })
        }
      </List >
    </Paper >);
  }
  return <div style={{ padding: '5px' }}>Radius</div>;
};
