import { isEmpty, every } from 'lodash';
import {
  DETAIL_PROPERTY_FETCH_REQUEST,
  DETAIL_PROPERTY_FETCH_ERROR,
  DETAIL_PROPERTY_FETCH_SUCCESS,
  DETAIL_ALL_NEARBY_FETCH_REQUEST,
  DETAIL_ALL_NEARBY_FETCH_ERROR,
  DETAIL_ALL_NEARBY_FETCH_SUCCESS,
  DETAIL_PROPERTY_CLEAR,
  FILTERED_QUERY_WITH_IDS_ERROR,
  FILTERED_QUERY_WITH_IDS_REQUEST,
  FILTERED_QUERY_WITH_IDS_SUCCESS,
  FILTERED_QUERY_WITH_IDS_QUERYING_COMPLETE
} from '../../constants/actionTypes';
import {
  handleError,
  unauthorizedRequest,
  requestData,
  receiveData
} from '../../common/apiActions';
import api, { getHeaders } from '../../services/api';
import { history } from '../../store';
import datatypes from '../../common/datatypes';

export function fetchProperty(id) {
  return (dispatch) => {
    dispatch(requestData(DETAIL_PROPERTY_FETCH_REQUEST, { id }));
    return api.read(`/properties/${id}`)
      .then((data) => dispatch(receiveData(DETAIL_PROPERTY_FETCH_SUCCESS, data)))
      .catch((response) => {
        if (response.status === 401) return dispatch(unauthorizedRequest());
        return response.json()
          .then((error) => {
            return dispatch(handleError(DETAIL_PROPERTY_FETCH_ERROR, error));
          }).then(() => {
            const { hash, search } = history.getCurrentLocation();
            history.push(`/research/map${search}${hash}`);
          });
      });
  };
}

export function fetchAllNearby(id) {
  return (dispatch) => {
    dispatch(requestData(DETAIL_ALL_NEARBY_FETCH_REQUEST, { id }));
    const datatypeEndpoints = ['lease', 'availability', 'sale', 'listing', 'development'];
    const fetchDataPromises = datatypeEndpoints.map((datatype) => 
      api.read(`/properties/${id}/nearby?type=${datatype}`)
        .then((data) => ({ datatype, data })));
    return Promise.all(fetchDataPromises)
      .then(((results) => {
        const data = {};
        results.forEach((result) => {
          data[result.datatype] = result.data;
        });
        dispatch(receiveData(DETAIL_ALL_NEARBY_FETCH_SUCCESS, data));
      }))
      .catch((response) => {
        if (response.status === 401) {
          return dispatch(unauthorizedRequest());
        }
        return dispatch(handleError(DETAIL_ALL_NEARBY_FETCH_ERROR, response));
      });
  };
}

export function clearProperty() {
  return (dispatch, getState) => {
    const { research: { propertyDetail: { dataUri } } } = getState();
    if (dataUri) {
      window.URL.revokeObjectURL(dataUri);
    }
    return dispatch({ type: DETAIL_PROPERTY_CLEAR });
  };
}

export function fetchAllDatatypeWithFilters(property, searches) {
  return (dispatch)  => {
    dispatch(requestData(FILTERED_QUERY_WITH_IDS_REQUEST));

    const apiRequests = [];

    // remove parcel searches - not needed for filtering
    delete searches.parcel;
    
    const { ...searchesDatatypesOnly } = searches;
    
    for (const datatype in searchesDatatypesOnly) {
      if (datatype === 'parcel') return;
      if (typeof searchesDatatypesOnly[datatype] === 'object' && searchesDatatypesOnly[datatype] !== null) {
        const { hasOnlyDefaultFilters } = datatypes?.[datatype] || {};
        const searchesFilter = searchesDatatypesOnly[datatype].filters;
        const hasDefaultFilters = hasOnlyDefaultFilters(searchesFilter);

        const areTestPropsEmpty = every(searchesDatatypesOnly[datatype].filters, (prop) => isEmpty(prop));

        const filterConditionsToNotFetch = hasDefaultFilters || (areTestPropsEmpty && !hasDefaultFilters);

        if (Object.prototype.hasOwnProperty.call(property, datatype)
        && Object.keys(searchesDatatypesOnly[datatype].query).length > 0
        && !filterConditionsToNotFetch) {
          const datatypePropertyItems = property[datatype];
          const filteredQuery = searchesDatatypesOnly[datatype].query;
          const allItemsIds = datatypePropertyItems.map((item) => item.id);
          filteredQuery.query.bool.must = [filteredQuery.query.bool.must[0]]; //reset filtered query id's for every search
          filteredQuery.query.bool.must.push({ bool: { should: [{ terms: { _id: allItemsIds } }] } });
          filteredQuery._source = ['_id'];

          const apiRequest = api.create('/_search', filteredQuery, {
            headers: getHeaders({ 'Elasticsearch-Query-Index': datatype }),
          })
            .then((results) => {
              dispatch(receiveData(FILTERED_QUERY_WITH_IDS_SUCCESS, results, { datatype }));
            })
            .catch((response) => {
              if (response.status === 401) return dispatch(unauthorizedRequest());
              return dispatch(handleError(FILTERED_QUERY_WITH_IDS_ERROR, response));
            });
          apiRequests.push(apiRequest);
        }
      }
    }
    Promise.all(apiRequests)
      .then(() => dispatch(requestData(FILTERED_QUERY_WITH_IDS_QUERYING_COMPLETE)))
      .catch((error) => dispatch(handleError(FILTERED_QUERY_WITH_IDS_ERROR, error)));
  };
}
